import { useCallback, useEffect, useMemo } from "react";
import { Paper, TableCell, TableContainer, TableRow } from "@material-ui/core";
import { Column, HeaderGroup, Row, useExpanded, useSortBy, useTable } from "react-table";
import { useSelector } from "react-redux";
import { StyledTable, StyledTableBody, StyledTableHead } from "modules/shared/components/StyledTable";
import { TaskCompletionCell } from './TaskCompletionCell';
import { LocationCell } from './LocationCell';
import { TaskCounterCell } from './TaskCounterCell';
import { TaskOverdueStatsCell } from './TaskOverdueStatsCell';
import { TaskByLocationHeaderCellRenderer } from "../TaskByLocationsView/cells/TaskByLocationCellRenderer"
import { TaskListPageLayout } from "../../components/TaskListPageLayout";
import { addPropertiesToSorting, useStoreLocationPredicate } from "./hooks";
import { useCurrentUser } from 'utils/hooks/useCurrentUser';
import { FeatureFlags } from '../../../../../utils/featureFlags';
import { useActions } from "utils/store/useActions";
import { useTaskByLocationStyles } from "../../hooks/useTaskByLocationStyles";
import { ColumnId } from './model';
import { selectLocationsByParentId } from "store/modules/hierarchy/hierarchySelectors";
import { Location } from "api/hierarchy/hierarchyModel";
import { StoreTaskStatusFilter } from "api/task/storeTask/storeTaskModel";
import { resetTaskRangeDate, resetTasksStatistics, resetTasksTags, setTaskRangeDate, setTaskTags } from "store/modules/task/statistics/statisticsActions";
import { selectFeatureFlags } from '../../../../../store/modules/featureFlags/selectors';
import { selectTasksStatsByLocationIds } from "store/modules/task/statistics/statisticsSelectors";
import { Trans } from "react-i18next";
import { TaskFilterContainer } from "modules/task/common/taskFilter/TaskFilterContainer";
import { TasksFilterType } from "modules/task/common/taskFilter/TypeTaskFilterMap";

const columnsCommon: Column<Location>[] = [
    {
        id: ColumnId.Location,
        Header: <Trans>Location</Trans>,
        accessor: 'id',
        Cell: LocationCell,
        defaultCanSort: true,
        disableSortBy: true,
        width: '*',
    },
    {
        id: ColumnId.Stats,
        Header: <Trans>Task Completion</Trans>,
        accessor: 'completation',
        Cell: TaskCompletionCell,
        width: 150,
        defaultCanSort: true,
    }
];
const expiredColumn: Column<Location> = {
    id: ColumnId.Expired,
    Header: <Trans>Tasks Expired</Trans>,
    accessor: 'expired',
    Cell: TaskCounterCell,
    width: 150,
    defaultCanSort: true,
}
const overdueColumns: Column<Location>[] = [
    {
        id: ColumnId.StatsInTime,
        Header: <Trans>On-Time Completion</Trans>,
        accessor: 'progress',
        Cell: TaskOverdueStatsCell,
        width: 150,
        defaultCanSort: true,
    },
    {
        id: ColumnId.Expired,
        Header: <Trans>Tasks Expired</Trans>,
        accessor: 'expired',
        Cell: TaskCounterCell,
        width: 150,
        defaultCanSort: true,
    },
    {
        id: ColumnId.Overdue,
        Header: <Trans>Overdue</Trans>,
        accessor: 'overdue',
        Cell: TaskCounterCell,
        width: 150,
        defaultCanSort: true,
    },
    {
        id: ColumnId.SubmittedOverdue,
        Header: <Trans>Submitted Overdue</Trans>,
        accessor: 'submittedOverdue',
        Cell: TaskCounterCell,
        width: 150,
        defaultCanSort: true,
    },
    {
        id: ColumnId.CompletedOverdue,
        Header: <Trans>Completed Overdue</Trans>,
        accessor: 'completedOverdue',
        Cell: TaskCounterCell,
        width: 150,
        defaultCanSort: true,
    },
];

const bodyRowRenderer = (row: Row<Location>) => {
    return (
        <TableRow {...row.getRowProps()}>
            {row.cells.map(cell => (
                <TableCell {...cell.getCellProps()}>
                    {cell.render('Cell')}
                </TableCell>
            ))}
        </TableRow>
    );
};

export const LocationsTreeView = () => {
    const tasksStatistics = useSelector(selectTasksStatsByLocationIds);
    const classes = useTaskByLocationStyles();
    const actions = useActions({ resetStatistics: resetTasksStatistics });
    const actionsTaskFilter = useActions({ 
        setRangeDate: setTaskRangeDate,
        resetRangeDate: resetTaskRangeDate,
        setTags: setTaskTags,
        resetTags: resetTasksTags
    });
    const locationsByParentId = useSelector(selectLocationsByParentId);
    const storeLocationPredicate = useStoreLocationPredicate(locationsByParentId);
    const { companyLocationId } = useCurrentUser() || {};
    const isOverdueColumsFeatureEnabled = useSelector(selectFeatureFlags)[FeatureFlags.OverdueTaskColumns];
    const isExpirableColumnsFeatureEnabled = useSelector(selectFeatureFlags)[FeatureFlags.TaksExpirable];

    const columns = useMemo(() => {
        let allColumns: Column<Location>[] = [...columnsCommon, ...overdueColumns]
        if(!isExpirableColumnsFeatureEnabled?.enabled){
            allColumns= allColumns.filter(col=>(col.id !== StoreTaskStatusFilter.Expired))
        }
        if(isOverdueColumsFeatureEnabled?.enabled){
            return allColumns
        }else if(isExpirableColumnsFeatureEnabled?.enabled){
            return ([...columnsCommon, expiredColumn])
        }
        return columnsCommon;
    }, [isOverdueColumsFeatureEnabled?.enabled, isExpirableColumnsFeatureEnabled?.enabled]);
    const data = useMemo(() => {
        const companylocation= companyLocationId
        ? locationsByParentId[companyLocationId]?.filter(storeLocationPredicate) ?? []
        : [];
        return addPropertiesToSorting(companylocation, tasksStatistics);
    }, [companyLocationId, locationsByParentId, storeLocationPredicate, tasksStatistics]);

    const getSubRows = useCallback(({ id }: Location) => {
        const locationsByParentIdRow = locationsByParentId[id]?.filter(storeLocationPredicate) ?? [];
        return addPropertiesToSorting(locationsByParentIdRow, tasksStatistics);
    }, [locationsByParentId, storeLocationPredicate, tasksStatistics]);

    const { headerGroups, rows, prepareRow, getTableProps, getTableBodyProps } = useTable<Location>(
        {
            columns,
            data,
            autoResetSortBy: false,
            getSubRows, 
            autoResetExpanded: false,
        },
        useSortBy,
        useExpanded,
    );

    const renderRow = useCallback((row: Row<Location>) => {
        prepareRow(row);

        return bodyRowRenderer(row);
    }, [prepareRow]);

    useEffect(() => {
        const resetStatistics = actions.current.resetStatistics;
        const resetTaskRangeDate = actionsTaskFilter.current.resetRangeDate;
        const resetTaskTags = actionsTaskFilter.current.resetTags;
        return () => {
            resetStatistics();
            resetTaskRangeDate();
            resetTaskTags();
        };
    }, [actions, actionsTaskFilter]);

    const renderHeaderRow = useCallback((headerGroup: HeaderGroup<any>) => {
        const headerGroupProps = headerGroup.getHeaderGroupProps();
        return (
            <TableRow {...headerGroupProps}>
                {headerGroup.headers.map(
                    header => {
                        const classesHeader = {...classes, headerCell: `${classes.headerCell} ${classes.headerCellLocation}`}
                        return(
                            <TaskByLocationHeaderCellRenderer
                                key={header.id}
                                classes={classesHeader}
                                header={header}
                            />
                    )}
                )}
            </TableRow>
        );
    }, [classes]);

    const header = useMemo(() => (
        <TaskFilterContainer taskFiltertype={TasksFilterType.LocationTree} locationId={companyLocationId ?? ''} />
    ),[companyLocationId])

    return (
        <TaskListPageLayout
            header={<>{header}</>}
        >
            <TableContainer component={Paper}>
                <StyledTable className={classes.table} {...getTableProps()}>
                    <StyledTableHead>
                        {headerGroups.map(renderHeaderRow)}
                    </StyledTableHead>
                    <StyledTableBody {...getTableBodyProps()}>
                        {rows.map(renderRow)}
                    </StyledTableBody>
                </StyledTable>
            </TableContainer>
        </TaskListPageLayout>
    );
};
