import React, { Suspense, useCallback, useEffect, useLayoutEffect, useMemo, useRef, useState } from 'react';
import type { BaseEmoji } from 'emoji-mart';
import { UserResponse } from 'stream-chat';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import { StreamChatGenerics } from 'stream-chat-react/dist/stories/utils';
import { EmojiPicker } from './EmojiPicker';
import {
    emojiReplace,
    toolbarFormatsOptions,
    getCursorPositionByFormat,
    getLastWord,
    getNewCursorPosition,
    giphy,
    hasEditIntheMiddle,
    isEndEmojiString,
    markdownConverter,
    useCustomTextEditorStyles,
    maxLengthCharacters,
    convertHTMLtoMarkdownGetStream,
    linkListOptions,
    bulletsOptions,
    bulletsFormatOptions,
    removeEmptyBlockComments,
    removeEmptyBlockBullets,
} from '../../utils/customTextEditor';
import { InputCounter } from 'components/basicInputs/InputCounter';
import { useFeatureState } from 'utils/hooks/useFeatureState';
import { FeatureFlags } from 'utils/featureFlags';
import { t } from 'i18next';

interface ICustomTextEditor {
    onChange: (value: string, selectionEnd: number, selectionStart: number) => void,
    placeholder?: string,
    valueText?: string,
    sendMessage: () => void,
    isActiveSomeTrigger: () => boolean,
    resetCount: number,
    mentionedUsers: UserResponse<StreamChatGenerics>[]
}

export const CustomTextEditor = ({
        onChange,
        placeholder = `${t('Type your message')}`,
        valueText = '',
        sendMessage,
        isActiveSomeTrigger,
        resetCount,
        mentionedUsers
    } : ICustomTextEditor) => {
    const isConnectFormatHyperlinkEnabled = useFeatureState(FeatureFlags.ConnectFormatHyperlink);
    const isConnectFormatBulletEnabled = useFeatureState(FeatureFlags.ConnectFormatBullet);
    const [value, setValue] = useState(removeEmptyBlockBullets(markdownConverter.makeHtml(valueText), true));
    const [caretPosition, setCaretPosition] = useState(0);
    const editorRef = useRef<ReactQuill | null>(null);
    const activeEditor = useRef<boolean>(false);
    const classes = useCustomTextEditorStyles([]);
    const toolbarAndFormatOptionsMemo = useMemo(() => {
        let toolbarOptions: Array<string|{}> = isConnectFormatHyperlinkEnabled ? [...toolbarFormatsOptions, ...linkListOptions] : toolbarFormatsOptions;
        let formatOptions: Array<string> = isConnectFormatHyperlinkEnabled ? [...toolbarFormatsOptions, ...linkListOptions] : toolbarFormatsOptions;
        if(isConnectFormatBulletEnabled){
            toolbarOptions = [ ...toolbarOptions, ...bulletsOptions ];
            formatOptions.push(bulletsFormatOptions);
        }
        return { toolbarOptions, formatOptions };
    },[isConnectFormatHyperlinkEnabled, isConnectFormatBulletEnabled]);

    const focusEditor = (position = 0) => {
        setTimeout(() => {
            if (editorRef.current) {
                const quill = editorRef.current.getEditor();
                quill.setSelection(position, 0);
                editorRef.current.focus();
            }
        }, 0);
    };

    const _replaceWord = useCallback((newValue: string) => {

        const markdown = markdownConverter.makeMarkdown(value);
        const markdownContent = removeEmptyBlockComments(markdown, isConnectFormatBulletEnabled);

        const postionAux = caretPosition ?? editorRef?.current?.getEditorSelection()?.index;
        const cursorPositionByFormat = getCursorPositionByFormat(editorRef?.current?.editor, postionAux);
        const lastWord = getLastWord(markdownContent, cursorPositionByFormat, newValue);

        if (!lastWord) return;

        const newWord = emojiReplace(lastWord);

        if (newWord == null) {
            if(caretPosition || (giphy === newValue && !markdownContent.includes(giphy))){
                const aux = newValue === giphy ? newValue.length : caretPosition;
                const newCursorPosition = getNewCursorPosition(newValue, markdownContent.length, aux);

                const markdownNewValue = removeEmptyBlockBullets(markdownConverter.makeHtml(newValue), isConnectFormatBulletEnabled);

                setValue(markdownNewValue);
                setCaretPosition(0);
                focusEditor(newCursorPosition);
                return;
            }
            return;
        }

        const newCursorPosition = getNewCursorPosition(newValue, markdownContent.length, getCursorPositionByFormat(editorRef?.current?.editor, postionAux));

        const textBeforeWord = newValue.slice(0, getCursorPositionByFormat(editorRef?.current?.editor, (postionAux || 0) - newWord.length));
        const textAfterCaret = newValue.slice(getCursorPositionByFormat(editorRef?.current?.editor, postionAux), -1);
        const newText = removeEmptyBlockBullets(markdownConverter.makeHtml(textBeforeWord + newWord + textAfterCaret), isConnectFormatBulletEnabled);


        setValue(newText);
        setCaretPosition(0);
        focusEditor(newCursorPosition);
    },[caretPosition, value, isConnectFormatBulletEnabled]);

    useLayoutEffect(() => {
        const markdownContent = markdownConverter.makeHtml(valueText);
        if(markdownContent !== value) {
            _replaceWord(valueText)
        }
    },[valueText, value, _replaceWord])

    
    useLayoutEffect(() => {
        focusEditor();
    },[])

    const handleChange = (html: string, delta: any, source: any, editor: ReactQuill.UnprivilegedEditor) => {
        const markdown = convertHTMLtoMarkdownGetStream(html, isConnectFormatHyperlinkEnabled);
        const markdownContent = removeEmptyBlockComments(markdown, isConnectFormatBulletEnabled);

        if(html === '<p><br></p>' || markdownContent === '<br>'){
            setValue('');
            onChange('',0 , 0);
            return;
        }

        if (
            (isActiveSomeTrigger() && ((hasEditIntheMiddle(editor) && delta?.ops[delta?.ops?.length - 1]?.insert === '\n' ) || (/<p><br><\/p>$/.exec(html))))
            || (!isActiveSomeTrigger() && hasEditIntheMiddle(editor) && delta?.ops[delta?.ops?.length - 1]?.insert === '\n')
            || delta?.ops[delta?.ops?.length - 1]?.attributes?.list
        ) {
            activeEditor.current = (
                delta?.ops[delta?.ops?.length - 1]?.attributes?.link 
                || delta?.ops[delta?.ops?.length - 1]?.attributes?.list)
                && hasEditIntheMiddle(editor);
            return;
        }

        const cursorPositionByFormat = getCursorPositionByFormat(editor, editorRef?.current?.getEditorSelection()?.index);

        if(!isActiveSomeTrigger()){
            const format = markdownContent === "/giphy\n\n" || isEndEmojiString(markdownContent) ? markdownContent : markdownContent.trim();
            setValue(html);
            onChange(format, cursorPositionByFormat, 0);
        }else{
            setValue(html);
            onChange(markdownContent, cursorPositionByFormat, 0);
        }
        if(delta?.ops[delta?.ops?.length - 1]?.attributes?.link){
            activeEditor.current = true;
            return;
        }
        activeEditor.current = false;
    }

    const insertJump = () => {
        const quillRef = editorRef?.current?.getEditor();
        
        const range = quillRef?.getSelection();
        let position = range ? range.index : 0;
        quillRef?.insertText(position, '');
    };

    const handleKeyDown = (event: React.KeyboardEvent<HTMLDivElement>) => {
        if(event.shiftKey && event.key === 'Enter'){
            event.preventDefault();
            insertJump();
            return;
        }
        if (event.key === 'Enter' && !isActiveSomeTrigger() && value !== '<p><br></p>' 
            && !activeEditor.current
            && (editorRef?.current?.editor?.getLength() || 0)  < maxLengthCharacters + 1) {
            event.preventDefault();
            sendMessage();
            setValue('');
            setCaretPosition(0);
        }
    };

    const insertText = useCallback((text: string) => {
        const quillRef = editorRef?.current?.getEditor();

        const range = quillRef?.getSelection();
        let position = range ? range.index : 0;
        quillRef?.insertText(position, text);
        quillRef?.setSelection(position + text.length, 0);
    },[]);

    const onSelectEmoji = useCallback(
        (emoji: BaseEmoji) => {
            insertText(emoji?.native);
        },
        [insertText],
    );

    useEffect(() => {
        if(resetCount > 0){
            setValue('');
            onChange('',0 , 0);
        }
    }, [resetCount, onChange]);

    useEffect(() => {
        if(mentionedUsers.length){
            setCaretPosition(editorRef?.current?.getEditorSelection()?.index || 0);
        }
    }, [mentionedUsers])

    return (
        <div style={{width: '100%'}} id="customTextEditor">
            <Suspense fallback={null}> 
                <ReactQuill
                    theme="snow"
                    value={value}
                    ref={editorRef}
                    onChange={handleChange}
                    placeholder={placeholder}
                    onKeyDown={handleKeyDown}
                    modules={{
                        toolbar: toolbarAndFormatOptionsMemo.toolbarOptions,
                        clipboard: {
                            matchVisual: false,
                        },
                    }}
                    formats={toolbarAndFormatOptionsMemo.formatOptions}
                    preserveWhitespace
                    className={`${classes.toolbar} ${ (editorRef?.current?.editor?.getLength() || 0) > maxLengthCharacters + 1 ? classes.error : ''}`}
                    bounds={'#customTextEditor'}
                />
                <InputCounter
                    className={classes.inputCounter}
                    current={(editorRef?.current?.editor?.getLength() || 0 )- 1}
                    max={maxLengthCharacters}
                />
                <EmojiPicker onSelectEmoji={onSelectEmoji}/>
            </Suspense>
        </div>
    )
}
