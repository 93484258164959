import { IViewDateRange } from 'modules/task/taskList/components/ViewDateRange/model';
import { ViewDateRange } from 'modules/task/taskList/components/ViewDateRange/ViewDateRange';
import React, { useCallback } from 'react'
import { resetTasksStatistics, setTaskRangeDate } from 'store/modules/task/statistics/statisticsActions';
import { FeatureFlags } from 'utils/featureFlags';
import { useFeatureState } from 'utils/hooks/useFeatureState';
import { useActions } from 'utils/store/useActions';

export const LocationDateRangeFilter = () => {
  const isTaskLocationRangeDateEnabled = useFeatureState(FeatureFlags.TaskLocationRangeDate);
  const actions = useActions({
    resetStatistics: resetTasksStatistics,
    setRangeDate: setTaskRangeDate,
  });

  const handleRangeDateChange = useCallback(
    (newRangeDate: IViewDateRange) => {
      actions.current.resetStatistics();
      actions.current.setRangeDate(newRangeDate)
    },
    [actions]
  )

  if (!isTaskLocationRangeDateEnabled) return <></>;

  return (
    <ViewDateRange onRangeDateChange={handleRangeDateChange} />
  )
}
