import React, { useRef } from 'react';
import { BaseInput, IBaseInputProps } from 'components/basicInputs/BaseInput';
import { IInputProps } from 'components/basicInputs/model';
import { Field, FieldAttributes } from 'formik';
import { makeStyles, Theme } from '@material-ui/core/styles';
import ReactQuill from 'react-quill';
import { bulletsOptions, linkListOptions, toolbarFormatsOptions } from 'modules/connect/messenger/utils/customTextEditor';
import { useConversationStyles } from 'modules/task/taskConversation/common/conversationStyles';
import { Box } from '@material-ui/core';

const useMarkdownInputStyles = makeStyles((theme: Theme) => ({
    container: {
        gridColumn: '1/3',
        alignItems: 'stretch',
        position: 'relative'
    }
}));


interface IMarkdownInputProps extends IInputProps, Pick<IBaseInputProps, 'classes'> {
    disabled?: boolean;
}

export function MarkdownInput({ name, id = name, disabled, ...baseInputProps }: IMarkdownInputProps) {
    const classes = useConversationStyles();
    const classesBase = useMarkdownInputStyles();
    const editorRef = useRef<ReactQuill | null>(null);
    
    return (
        <Box width={'100%'} className={classesBase.container} id="markdownEditor" >
            <BaseInput id={id} {...baseInputProps} children={<></>}/>
            <Field name={name}>
                {({ field }: FieldAttributes<any>) => 
                    <ReactQuill
                        theme="snow"
                        value={field.value}
                        onChange={field.onChange(field.name)}
                        ref={editorRef}
                        formats={EditorFormats}
                        preserveWhitespace
                        className={`${classes.toolbar}`}
                        modules={{
                            toolbar: EditorTtoolbarOptions,
                        }}
                        readOnly={disabled}
                        bounds={'#markdownEditor'}
                    />
            }
            </Field>
        </Box>
    );
}

const EditorFormats = [
    'size',
    'bold',
    'italic',
    'underline',
    'strike',
    'blockquote',
    'list',
    'bullet',
    'indent',
    'link',
    'image',
    'video',
    'mention',
    'color',
    'align',
    'header'
];
const EditorTtoolbarOptions = [
    ...toolbarFormatsOptions,
     ...linkListOptions,
     ...bulletsOptions,
     ...['blockquote'],
     ...[{ 'header': [1, 2, 3, false] }]
];
